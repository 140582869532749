<template>
  <div>
    <div v-if="mediaAvailable" @contextmenu="handleContextMenu">
      <div v-show="sourcesAvailable">
        <video :ref="videoId" :id="videoId" class="video-player-box video-js video-getnext">
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
          </p>
        </video>
      </div>
      <div v-if="!sourcesAvailable" class="video-placeholder">
        {{ $t('videoNotPlayable') }}
      </div>
    </div>
    <div v-else class="video-placeholder">
      {{ $t('videoConverting') }}
    </div>
  </div>
</template>
<script>

import videojs from 'video.js'
require('videojs-contrib-quality-levels')
require('@/lib/player/hlsQualitySelector')
require('videojs-seek-buttons')

export default {
  props: ['video'],

  data() {
    return {
      hlsSource: false,
      playsinline: true,
      player: null,
      seekButtons: {
        forward: 15,
        back: 15
      },
      options: {}
    }
  },
  computed: {
    videoId() {
      return `video_${this.video.name.replaceAll('-', '')}`
    },
    sourcesAvailable() { return (this.options.sources || []).length },
    mediaAvailable() { return this.video.master || this.video.path },
    aspectRatio() {
      return (this.video.variants && this.video.variants[0].displayAspectRatio) || '16:9'
    }
  },
  methods: {
    setSource() {
      if (this.mediaAvailable) {
        this.hlsSource = this.video.mimeType === 'application/x-mpegurl'
        if (this.hlsSource) {
          return [{
            type: this.video.mimeType,
            src: this.video.master
          }]
        }
        // Legacy
        return [{
          type: this.video.mimeType,
          src: this.video.path
        }]
      }
      return []
    },
    handleContextMenu(event) {
      event.preventDefault()
    },

    init() {
      if (!this.$refs[this.videoId]) {
        console.log('Video player not initialized for id', this.videoId)
        return
      }

      this.options = {
        fluid: true,
        aspectRatio: this.aspectRatio,
        playsinline: true,
        autoplay: false,
        controls: true,
        preload: 'auto',
        controlBar: {
          volumePanel: { inline: true }
        },
        poster: this.video.poster || '',
        sources: this.setSource(),
        language: this.$i18n.locale,
        userActions: {
          hotkeys: event => {
            switch (event.code) {
              case 'Space':
                if (this.player.paused()) {
                  this.player.play()
                } else {
                  this.player.pause()
                }
                break
              case 'ArrowRight':
                this.player.currentTime(this.player.currentTime() + this.seekButtons.forward)
                break
              case 'ArrowLeft':
                this.player.currentTime(this.player.currentTime() - this.seekButtons.back)
                break
              case 'ArrowUp':
                this.player.volume(this.player.volume() + 0.05)
                break
              case 'ArrowDown':
                this.player.volume(this.player.volume() - 0.05)
                break
              case 'KeyM':
                if (this.player.muted()) {
                  this.player.muted(false)
                } else {
                  this.player.muted(true)
                }
                break
              case 'KeyF':
                if (this.player.isFullscreen()) {
                  this.player.exitFullscreen()
                } else {
                  this.player.requestFullscreen()
                }
                break

              default:
                break
            }
          }
        },
        html5: {
          vhs: {
            overrideNative: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        }
      }
      this.player = videojs(this.$refs[this.videoId], this.options)
      this.player.hlsQualitySelector({
        displayCurrentQuality: true
      })
      this.player.seekButtons({
        forward: 10,
        back: 10
      })

      this.player.on('ready', () => { })
      this.player.on('ended', () => { })
      this.player.on('play', () => { })
      this.player.on('pause', () => { })
      this.player.on('error', () => { })
      this.player.on('waiting', () => { })
      this.player.on('emptied', () => { })
    }
  },
  mounted() {
    setTimeout(() => {
      this.init()
    }, 200)
  },
  beforeDestroy() {
    if (this.player) this.player.dispose()
  }
}
</script>

<style lang="scss">
  @import 'assets/sass/_variables';
  @import 'assets/sass/video-player';

  .video-placeholder {
    width: 100%;
    font-style: italic;
    background-color: #b0b0b0;
  }

  // .hidden-controls {
  //   .vjs-progress-control,
  //   .vjs-current-time,
  //   .vjs-time-divider,
  //   .vjs-time-control,
  //   .vjs-playback-rate {
  //     display: none
  //   }
  // }

  // .vjs-tech { object-fit: cover; }

  .vjs-poster { background-size: cover; }

  @media screen and (max-width: $mobile) {
    .vjs-seek-button {
      display: none !important;
    }
  }
</style>
