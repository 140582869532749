var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"placeholder"},[_c('div',{staticClass:"placeholder-content-wrapper"},[_c('div',{staticClass:"placeholder-text"},[_c('div',[_c('section',[(!_vm.hasOffers && _vm.post.limitToFollowers)?_c('div',[_c('p',{staticClass:"placeholder-heading"},[_vm._v(_vm._s(_vm.$t('placeholderFeature.intro')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('placeholderFeature.offerAccessToFollowers')))]),_vm._v(" "),(_vm.user.artist)?_c('followButton',{attrs:{"user":_vm.user,"fullWidth":false,"withIcon":false,"outlined":true,"minWidth":true}}):_vm._e()],1):(!_vm.hasOffers)?_c('p',{staticClass:"placeholder-heading"},[_vm._v(_vm._s(_vm.artist.nickname)+" "+_vm._s(_vm.$t('offerNoAccess')))]):(_vm.post.limitToFollowers && (_vm.cheapestReward || _vm.cheapestProduct))?_c('div',[_c('p',{staticClass:"placeholder-heading"},[_vm._v(_vm._s(_vm.$t('placeholderFeature.intro')))]),_vm._v(" "),(_vm.cheapestReward && _vm.cheapestProduct)?_c('p',[_vm._v(_vm._s(_vm.$t('placeholderFeature.option1', { amount: _vm.centToEuro(_vm.cheapestReward.amount, true) })))]):(_vm.cheapestReward)?_c('p',[_vm._v(_vm._s(_vm.$t('placeholderFeature.option2', { amount: _vm.centToEuro(_vm.cheapestReward.amount, true) })))]):_c('p',[_vm._v(_vm._s(_vm.$t('placeholderFeature.option3', { amount: _vm.centToEuro(_vm.cheapestProduct.amount, true) })))]),_vm._v(" "),_c('div',{staticClass:"is-flex is-justify-content-space-evenly is-fullwidth"},[(_vm.user.artist)?_c('followButton',{attrs:{"user":_vm.user,"fullWidth":false,"withIcon":false,"outlined":true,"minWidth":true}}):_vm._e(),_vm._v(" "),(_vm.cheapestReward)?_c('button',{staticClass:"button is-primary same-min-width is-clickable is-outlined",on:{"click":function($event){_vm.$router.push(
                    _vm.localePath({
                      name: 'id-post-slug-postId',
                        params: {
                          id: _vm.artist.urlAlias,
                          postId: _vm.post._id,
                          slug: _vm.urlSlug(_vm.post.title),
                          title: `${_vm.artist.nickname} - ${_vm.post.title}`,
                          itemType: 'reward'
                        }
                    })
                  )}}},[_vm._v(_vm._s(_vm.$t('placeholderFeature.subscribe')))]):_vm._e(),_vm._v(" "),(_vm.cheapestProduct)?_c('button',{staticClass:"button is-primary same-min-width is-clickable is-outlined",on:{"click":function($event){_vm.$router.push(
                    _vm.localePath({
                      name: 'id-post-slug-postId',
                      params: {
                        id: _vm.artist.urlAlias,
                        postId: _vm.post._id,
                        slug: _vm.urlSlug(_vm.post.title),
                        title: `${_vm.artist.nickname} - ${_vm.post.title}`,
                        itemType: 'single-reward'
                      }
                    })
                  )}}},[_vm._v(_vm._s(_vm.$t('placeholderFeature.purchase')))]):_vm._e()],1)]):_c('div',[_c('p',{staticClass:"placeholder-heading"},[_vm._v(_vm._s(_vm.$t('placeholderFeature.intro')))]),_vm._v(" "),(_vm.cheapestReward && _vm.cheapestProduct)?_c('p',[_vm._v(_vm._s(_vm.$t('placeholderFeature.option4', { amount: _vm.centToEuro(_vm.cheapestReward.amount, true) })))]):(_vm.cheapestReward)?_c('p',[_vm._v(_vm._s(_vm.$t('placeholderFeature.option5', { amount: _vm.centToEuro(_vm.cheapestReward.amount, true) })))]):_c('p',[_vm._v(_vm._s(_vm.$t('placeholderFeature.option5', { amount: _vm.centToEuro(_vm.cheapestProduct.amount, true) })))]),_vm._v(" "),_c('div',{staticClass:"is-flex is-justify-content-space-evenly is-fullwidth"},[(_vm.cheapestReward)?_c('button',{staticClass:"button is-primary same-min-width is-clickable is-outlined",on:{"click":function($event){_vm.$router.push(
                    _vm.localePath({
                      name: 'id-post-slug-postId',
                        params: {
                          id: _vm.artist.urlAlias,
                          postId: _vm.post._id,
                          slug: _vm.urlSlug(_vm.post.title),
                          title: `${_vm.artist.nickname} - ${_vm.post.title}`,
                          itemType: 'reward'
                        }
                    })
                  )}}},[_vm._v(_vm._s(_vm.$t('placeholderFeature.subscribe')))]):_vm._e(),_vm._v(" "),(_vm.cheapestProduct)?_c('button',{staticClass:"button is-primary same-min-width is-clickable is-outlined",on:{"click":function($event){_vm.$router.push(
                  _vm.localePath({
                    name: 'id-post-slug-postId',
                    params: {
                      id: _vm.artist.urlAlias,
                      postId: _vm.post._id,
                      slug: _vm.urlSlug(_vm.post.title),
                      title: `${_vm.artist.nickname} - ${_vm.post.title}`,
                      itemType: 'single-reward'
                    }
                  })
                )}}},[_vm._v(_vm._s(_vm.$t('placeholderFeature.purchase')))]):_vm._e()])])])]),_vm._v(" "),(!_vm.postProducts.length && !_vm.postRewards.length && !_vm.post.limitToFollowers)?_c('div',{staticClass:"mt-10"},[_vm._v(_vm._s(_vm.$t('postWithoutActivePurchasable')))]):_vm._e()]),_vm._v(" "),(_vm.post.stats)?_c('div',{staticClass:"placeholder-icons mt-20"},[_c('ul',{staticClass:"icon-holder"},[(_vm.post.stats.images)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.post.stats.images} ${_vm.$t('posts.media.images')}`}},[_c('i',{staticClass:"icon-photo active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.videos)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.post.stats.videos} ${_vm.$t('posts.media.videos')}`}},[_c('i',{staticClass:"icon-video active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.audios)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.post.stats.audios} ${_vm.$t('posts.media.audios')}`}},[_c('i',{staticClass:"icon-audio active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.pdfs)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.post.stats.pdfs} ${_vm.$t('posts.media.pdfs')}`}},[_c('i',{staticClass:"icon-pdf active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.zips)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.post.stats.zips} ${_vm.$t('posts.media.zips')}`}},[_c('i',{staticClass:"icon-zip active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.externalLink)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.$t('posts.media.extlink_included')}`}},[_c('i',{staticClass:"icon-link active"})])]):_vm._e(),_vm._v(" "),(_vm.post.stats.livestream)?_c('li',[_c('abbr',{attrs:{"title":`${_vm.$t('posts.media.livestream_included')}`}},[_c('i',{staticClass:"icon-live active"})])]):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }