var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{class:{
  sideview: _vm.sideview,
  listview: _vm.listview,
  highlight: _vm.selectedReward === _vm.reward.amount
}},[_c('div',{staticClass:"reward-container",class:{
    'active-reward-ribbon': _vm.reward.activePledge || _vm.reward.isPending
  }},[(_vm.reward.activePledge)?_c('div',{staticClass:"ribbon"},[_c('span',[_vm._v(_vm._s(_vm.$t('active')))])]):_vm._e(),_vm._v(" "),(_vm.reward.isPending)?_c('div',{staticClass:"ribbon"},[_c('span',[_vm._v(_vm._s(_vm.$t('pending')))])]):_vm._e(),_vm._v(" "),(_vm.reward.status === 'draft')?_c('div',{staticClass:"ribbon draft"},[_c('span',[_vm._v(_vm._s(_vm.$t('draft')))])]):_vm._e(),_vm._v(" "),(_vm.reward.status === 'scheduled')?_c('div',{staticClass:"ribbon scheduled"},[_c('span',[_vm._v(_vm._s(_vm.$t('scheduled')))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"reward",class:{
      activePledge: _vm.reward.activePledge
    },attrs:{"id":`reward_${_vm.reward._id}`}},[_c('div',{class:{ 'left-column': !_vm.oneColumnLayout }},[_c('div',{staticClass:"reward-img-sideview"},[_c('img',{staticClass:"reward-img",style:({ backgroundImage: `url(${_vm.rewardImage})` }),attrs:{"src":_vm.rewardImage}})]),_vm._v(" "),_c('div',{staticClass:"reward-img-listview",style:({ backgroundImage: `url(${_vm.rewardImage})` })}),_vm._v(" "),_c('portal-target',{attrs:{"name":`threeColumns_${_vm.reward._id}`}})],1),_vm._v(" "),_c('div',{staticClass:"text-reward-cont"},[_c('div',{staticClass:"mr-grid"},[_c('div',{staticClass:"col1"},[_c('h1',{staticClass:"reward-title"},[_vm._v(_vm._s(_vm.reward.name))]),_vm._v(" "),_c('ul',{staticClass:"reward-subtitle"},[_c('li',{staticClass:"has-text-left"},[_c('span',{staticClass:"nowrap"},[_vm._v(_vm._s(_vm.centToEuro(_vm.reward.amount, true))+" "+_vm._s(_vm.$t('perMonth')))])]),_vm._v(" "),(_vm.settings && _vm.settings.exchangeRates && _vm.settings.exchangeRates.rates.USD)?_c('li',{staticClass:"has-text-left li-footnote"},[_vm._v(_vm._s(_vm.$t('rewards.equalsToAboutPerMonth', {
                    amount: _vm.centToCurrency(_vm.reward.amount,
                      _vm.settings.exchangeRates.rates.USD, 'USD')
                  })))]):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"mr-grid"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"reward-description formatted-lists"},[_c('span',{class:{ 'bright-colors': _vm.listview && _vm.oneColumnLayout },domProps:{"innerHTML":_vm._s(_vm.reward.description)}})]),_vm._v(" "),_c('portal-target',{attrs:{"name":`oneColumn_${_vm.reward._id}`}})],1)])])])]),_vm._v(" "),_c('portal',{attrs:{"to":_vm.columnLayout}},[(!_vm.reward.isPending && !_vm.reward.activePledge)?_c('div',[(_vm.reward.status === 'published')?_c('div',{staticClass:"action-row"},[(_vm.artist.isBoarded && !_vm.artist.hasPendingPayment)?_c('a',{staticClass:"col1",attrs:{"href":_vm.checkoutUrl(_vm.reward._id)}},[_c('span',{staticClass:"button pledge-button is-fullwidth is-outlined has-text-weight-bold",class:{ 'is-white': _vm.sideview }},[_c('span',{staticClass:"pledge-button-text",class:{
              'is-medium': ['sm', 'md'].includes(_vm.$mq),
              'is-small': _vm.$mq === 'xl',
              'is-smallest': ['lg', 'lgr'].includes(_vm.$mq)
            }},[_vm._v(_vm._s(_vm.$t(_vm.pledgeControlButtonText)))])])]):_c('div',{staticClass:"col1 has-text-centered"},[(_vm.artist.hasPendingPayment)?_c('h5',{staticClass:"title is-5 has-text-weight-light"},[_vm._v(_vm._s(_vm.$t('pendingPayment')))]):_c('h5',{staticClass:"title is-5 has-text-weight-light"},[_vm._v(_vm._s(_vm.$t('rewardAvailableSoon')))])])]):_c('div',{staticClass:"action-row"},[_c('section',{staticClass:"col1"},[_c('span',{staticClass:"button is-white is-fullwidth has-text-weight-bold"},[(_vm.reward.status === 'scheduled')?_c('span',[_vm._v(_vm._s(_vm.$t('until'))+": "+_vm._s(_vm.formatDate(_vm.reward.publishDateTime)))]):_c('span',[_vm._v(_vm._s(_vm.$t('notPublishedYet')))])])])])]):_vm._e(),_vm._v(" "),(_vm.reward.isCancelled && _vm.reward.accessUntil)?_c('div',[_c('div',{staticClass:"action-row"},[_c('section',{staticClass:"col1 has-text-centered"},[_c('hr'),_vm._v(" "),_c('h5',{},[_vm._v(_vm._s(_vm.$t('rewards.subscriptionCancelled')))]),_vm._v(" "),_c('h5',[_vm._v("\n            "+_vm._s(_vm.$t('rewards.accessableContentUntil'))+":\n            "),_c('br'),_vm._v("\n            "+_vm._s(_vm.localeDate(_vm.reward.accessUntil, _vm.$i18n.locale))+"\n          ")])])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }