<template>
  <article v-bind:class="{
    sideview: sideview,
    listview: listview,
    highlight: selectedProduct === product.amount
  }">
    <div class="product-container" v-bind:class="{
      'active-product-ribbon': product.isAcquired || product.isPending
    }">
      <div v-if="product.isSold" class="ribbon">
        <span>{{ $t('active') }}</span>
      </div>
      <div v-if="product.isPending" class="ribbon">
        <span>{{ $t('pending') }}</span>
      </div>
      <div v-if="product.status === 'draft'" class="ribbon draft">
        <span>{{ $t('draft') }}</span>
      </div>
      <div v-if="product.status === 'scheduled'" class="ribbon scheduled">
        <span>{{ $t('scheduled') }}</span>
      </div>
      <div class="product" :id="`product_${product._id}`" v-bind:class="{
        activePledge: product.activePledge
      }">
        <div :class="{ 'left-column': !oneColumnLayout }">
          <div class="product-img-sideview">
            <img class="product-img" :style="{ backgroundImage: `url(${productImage})` }" :src="productImage" />
          </div>
          <div class="product-img-listview" :style="{ backgroundImage: `url(${productImage})` }" />

          <portal-target :name="`threeColumns_${product._id}`"></portal-target>
        </div>
        <div class="text-product-cont">
          <div class="mr-grid">
            <div class="col1">
              <h1 class="product-title">{{ product.name }}</h1>
              <div v-if="!product.minAmount" class="product-meta">
                <ul class="product-subtitle">
                  <li class="has-text-left">
                    <span>{{ centToEuro(product.amount, true) }} ({{ $t('oneTime') }})</span>
                  </li>
                  <li class="has-text-left li-footnote"
                    v-if="settings && settings.exchangeRates && settings.exchangeRates.rates.USD">{{
                      $t('singleRewards.equalsToAboutOnce', {
                        amount: centToCurrency(product.amount,
                          settings.exchangeRates.rates.USD, 'USD')
                      }) }}</li>
                </ul>
              </div>
              <div v-else class="product-meta">
                <ul class="product-subtitle">
                  <li class="has-text-left">
                    <span>{{ $t('atLeast') }} {{ centToEuro((product.minAmount || product.amount), true) }} ({{
                      $t('oneTime') }})</span>
                  </li>
                  <li class="has-text-left li-footnote"
                    v-if="settings && settings.exchangeRates && settings.exchangeRates.rates.USD">{{
                      $t('singleRewards.equalsToAboutOnce', {
                        amount: centToCurrency(product.minAmount,
                          settings.exchangeRates.rates.USD, 'USD')
                      }) }}</li>
                </ul>
              </div>
              <div v-if="limitedProduct" class="stock-limit">
                <span
                  v-html="$t('singleRewards.stockRemainingOneLine', { stockRemaining: product.stockRemaining })"></span>
              </div>
            </div>
          </div>

          <div class="mr-grid">
            <div class="col1">
              <div class="product-description formatted-lists">
                <span :class="{ 'bright-colors': listview && oneColumnLayout }" v-html="product.description"></span>
              </div>
              <portal-target :name="`oneColumn_${product._id}`"></portal-target>
            </div>
          </div>
        </div>
      </div>
    </div>
    <portal v-bind:to="columnLayout">
      <div v-if="!product.isPending">
        <div v-if="product.status === 'published'" class="action-row">
          <a v-if="artist.isBoarded" :href="checkoutUrl(product._id)" style="width: 100%;">
            <span :class="{ 'is-white': this.sideview }"
              class="button pledge-button is-fullwidth is-outlined has-text-weight-bold">
              <span class="pledge-button-text" :class="{
                'is-medium': ['sm', 'md'].includes($mq),
                'is-small': $mq === 'xl',
                'is-smallest': ['lg', 'lgr'].includes($mq)
              }">{{ $t(purchaseControlButtonText) }}</span>
            </span>
          </a>
          <div class="col1 has-text-centered" v-else>
            <h5 class="title is-5 has-text-weight-light">{{ $t('singleRewardAvailableSoon') }}</h5>
          </div>
        </div>
        <div v-else class="action-row">
          <section class="col1">
            <span class="button is-white is-fullwidth has-text-weight-bold">
              <span v-if="product.status === 'scheduled'">{{ $t('until') }}: {{ formatDate(product.publishDateTime)
                }}</span>
              <span v-else>{{ $t('notPublishedYet') }}</span>
            </span>
          </section>
        </div>
      </div>
      <div v-if="product.isCancelled && product.accessUntil">
        <div class="action-row">
          <section class="col1 has-text-centered">
            <hr />
            <h5 class>{{ $t('singleRewards.subscriptionCancelled') }}</h5>
            <h5>
              {{ $t('singleRewards.accessableContentUntil') }}:
              <br />
              {{ localeDate(product.accessUntil, $i18n.locale) }}
            </h5>
          </section>
        </div>
      </div>
    </portal>
    <modal :class="{ 'is-hidden': !modalActive }" @close="closeModal()" @submitLogin="submitCredentials()"
      defaultHeader="true" defaultFooter="true">
      <div slot="header">{{ modalTitle }}</div>
      <div slot="body" class="product-grow">
        <div :style="`background-image: url(${productImage})`" class="product-background">&nbsp;</div>
        <div class="is-overlay">
          <section class="product-hero scale-hack">
            <div class="content">
              <div class="modal-card animated slideInDown">
                <section class="modal-card-body">
                  <div class="register-login-form panels">
                    <el-tabs v-model="activeTabName">
                      <el-tab-pane label="Login" name="login-tab">
                        <div id="loginForm" class="panel" role="tabpanel" aria-hidden="true">
                          <div class="columns">
                            <div class="column">
                              {{ $t('registrationToPurchase') }}
                              <nuxt-link :to="localePath({ name: 'portal-signup' })">{{ $t('register') }}</nuxt-link>
                              {{ $t('withValidEmail') }}
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column is-8 is-offset-2 mdc-theme--light">
                              <socialLogin @logged="$emit('logged'); startCheckoutProcess()"
                                @registration="setSocialRegistration" :divider="!socialRegistration" :params="listview ? {
                                  returnRouteObject: {
                                    route: {
                                      name: `id-products___${$i18n.locale}`,
                                      params: {
                                        id: artist.urlAlias,
                                        amount: product.amount,
                                        rewardId: product._id,
                                        scrollAnimation: true
                                      }
                                    },
                                    locales: $root.$i18n['locales'],
                                    locale: $i18n.locale
                                  }
                                } : {}"></socialLogin>
                              <form method="post" @submit.prevent="submitCredentials">
                                <input-text v-model="email" label="Email" validate="required" />

                                <input-text v-model="password" type="password" label="Password" validate="required" />

                                <div class="control mt-50">
                                  <button type="submit" :disabled="invalidLoginForm"
                                    class="button is-primary is-outlined is-fullwidth is-medium">Login</button>
                                  <input type="submit" style="position: absolute; left: -9999px" />
                                </div>
                              </form>
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column mdc-theme--light">
                              <p class="has-text-centered" v-html="$t('problemLogin')"></p>
                            </div>
                          </div>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </modal>
  </article>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import modal from '~/components/modal'
import socialLogin from '~/components/social-login'

export default {
  inject: ['$validator'],
  props: ['product', 'sideview', 'listview'],
  components: { modal, socialLogin },

  data: () => ({
    selectedProduct: false,
    activeTabName: 'login-tab',
    email: null,
    validEmail: false,
    password: null,
    validPassword: false,
    invalidLoginForm: true,
    socialRegistration: false,
    hasError: false,
    modalActive: false,
    success: false,
    loading: false,
    orderAmount: 0
  }),

  computed: {
    modalTitle() {
      if (this.isAuthenticated) {
        return 'Purchase to unlock this item'
      } else return 'Login or register to purchase this product'
    },
    productImage() {
      if (
        !this.artist ||
        !this.product ||
        !this.product.image
      ) return ''

      let width = 636

      if (this.sideview) {
        width = this.oneColumnLayout ? 984 : 636
      }
      if (this.listview) {
        width = this.oneColumnLayout ? 636 : 480
      }

      return `/services/media/image/${this.artist._id}/${this.product.image.name}/${width}/0/image.webp`
    },
    oneColumnLayout() { return ['sm', 'md'].includes(this.$mq) },
    columnLayout() {
      return this.oneColumnLayout || this.sideview
        ? `oneColumn_${this.product._id}`
        : `threeColumns_${this.product._id}`
    },
    limitedProduct() {
      return Number.isInteger(this.product.stockRemaining)
    },
    purchaseControlButtonText() {
      if (this.product.stockRemaining === 0) {
        return 'payment.controls.soldOut'
      }
      if (this.selectedProduct && this.isAuthenticated) {
        return 'payment.controls.cancelPaymentProcess'
      }
      if (this.product.isSold) {
        return 'payment.controls.purchaseAgain'
      }
      return 'payment.controls.pledgeForSingleReward'
    },
    ...mapState(['user', 'artist', 'isAuthenticated', 'settings'])
  },
  watch: {
    email: function () {
      return this.$validator
        .validate('email', this.email)
        .then((response) => {
          this.validEmail = response
          this.invalidLoginForm = !(this.validEmail && this.validPassword)
        })
    },
    password: function () {
      return this.$validator
        .validate('password', this.password)
        .then((response) => {
          this.validPassword = response
          this.invalidLoginForm = !(this.validEmail && this.validPassword)
        })
    }
  },
  methods: {
    closeModal() {
      document.body.classList.toggle('unscrollable')
      this.modalActive = false
      if (this.success) {
        return this.pledgeSuccess()
      }
    },
    async pledgeSuccess() {
      const locale = await this.$i18n.locale
      if (this.$router.currentRoute.fullPath === `/${this.artist.urlAlias}`) {
        this.$router.push({
          name: `id___${locale}`,
          params: { id: this.artist.urlAlias }
        })
      } else {
        this.$router.push({ path: `/${this.artist.urlAlias}` })
      }
    },
    async submitRegistration() {
      const valid = await this.$validator.validateAll()
      if (valid) {
        const result = await this.apiPost('/api/private/register', this.$data)
        if (result) {
          const data = await this.apiPost(
            'api/private/authenticate',
            this.$data
          )
          if (data) this.login(data)
        }
      }
    },
    setSocialRegistration(value) {
      this.socialRegistration = value
    },
    async submitCredentials() {
      const data = await this.apiPost('/api/private/authenticate', {
        email: this.$data.email,
        password: this.$data.password
      })
      if (data) {
        this.closeModal()
        this.loginPayment(data)
        this.purchase(this.product._id)
      }
    },
    async startCheckoutProcess(data) {
      this.closeModal()
      if (data) {
        this.loginPayment(data)
      }
      await this.reloadPurchasables(this.artist.urlAlias)
      const foundProduct = this.artist.products.find(p => p._id === this.product._id)

      if (foundProduct) {
        this.purchase(this.product._id)
      }
    },
    scrollToMe(target) {
      const elem = target
        ? document.getElementById(`product_${this.product._id}_${target}`)
        : document.getElementById(`product_${this.product._id}`)

      if (elem) {
        this.$scrollTo(elem)
      }
    },
    scrollToMyProductPayment(productId) {
      if (productId === this.product._id) {
        this.scrollToMe('payment')
      }
    },
    productSelected(productId) {
      if (productId !== this.product._id) {
        this.selectedProduct = false
      }
    },
    onCheckTermsAccepted(val) {
      console.log('onCheckTermsAccepted', val)
    },
    ...mapActions(['loginPayment', 'reloadPurchasables'])
  },
  mounted() {
    if (
      this.$route.params.productId &&
      this.$route.params.productId === this.product._id
    ) {
      if (this.$route.query.payload) {
        this.verify(this.$route.query.payload)
      }
    }

    if (this.highlight === this.product.amount) {
      this.selectedProduct = this.product._id
    }

    this.$root.$on('productSelected', this.productSelected)
    this.$root.$on('scrollToProductPayment', this.scrollToMyProductPayment)
  },
  created() {
    this.$validator.attach({
      name: 'email',
      rules: {
        required: true,
        email: true
      }
    })
    this.$validator.attach({
      name: 'password',
      rules: {
        required: true
      }
    })

    if (this.product) {
      this.orderAmount = this.product.minAmount > 0
        ? this.centToEuro(this.product.minAmount)
        : this.centToEuro(this.product.amount)
    }
  },
  beforeDestroy() {
    this.$validator.detach('email')
    this.$validator.detach('password')
    this.$root.$off('productSelected', this.productSelected)
    this.$root.$off('scrollToProductPayment', this.scrollToMyProductPayment)
  }
}
</script>

<style lang="scss">
@import "assets/sass/_variables";

$light: 300;
$semi-bold: 600;
$bold: 700;
$font-regular: 400;

$txt-product-title: 1.4rem;
$text-summary: 0.8rem;
$text-action-btn: 1rem;
$text-product-description: 0.8rem;

.pledge-button-text {
  &.is-medium {
    font-size: 1rem;
  }

  &.is-small {
    font-size: 0.9rem;
  }

  &.is-smallest {
    font-size: 0.75rem;
  }
}

.highlight {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.sideview,
.listview {
  margin-bottom: 20px;

  strong {
    font-weight: bold;
  }
}

.active-product-ribbon {
  .ribbon span {
    background-color: var(--primary-color) !important;
  }
}

.ribbon {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: -1px;
  z-index: 2;

  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 1em;
    line-height: 1em;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    right: -35px;
    top: 20px;
    transform: rotate(-45deg);
  }

  &.draft {
    span {
      background-color: #08ae2d;
    }
  }

  &.scheduled {
    span {
      background-color: #fed34b;
    }
  }
}

.stock-limit {
  margin-top: 4px;
  position: relative;

  span {
    display: block;
    padding: 4px 2px;
    color: #83838a;
    font-size: 0.8em;
    line-height: 1.2em;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-align: left;
  }
}

.product-container {
  position: relative;
  background-color: #1e1b26;
  margin: 0 auto;

  .product {
    background: linear-gradient(to bottom,
        rgba(255, 255, 255, 0) 38%,
        rgba(255, 255, 255, 0.06) 39%,
        rgba(30, 27, 38, 0.88) 53%,
        rgba(30, 27, 38, 1) 55%);

    background-size: cover;

    display: block;
    border-radius: 4px;

    .price-row {
      position: relative;
      z-index: 2;
      font-size: 20px;
      font-weight: bold;
      color: var(--primary-color);
      text-align: center;
    }

    .product-img {
      width: 100%;
      position: relative;
      margin-top: -1px;
      background-size: cover;
      -webkit-mask-image: -webkit-gradient(linear,
          left top,
          left bottom,
          color-stop(0, rgba(0, 0, 0, 1)),
          color-stop(0.35, rgba(0, 0, 0, 1)),
          color-stop(0.5, rgba(0, 0, 0, 1)),
          color-stop(0.65, rgba(0, 0, 0, 1)),
          color-stop(0.85, rgba(0, 0, 0, 0.6)),
          color-stop(1, rgba(0, 0, 0, 0)));
    }

    .text-product-cont {
      padding: 0px 12px;
    }
  }

  .action-row {
    margin-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .share-btn {
      text-align: right;
      padding-top: 5px;

      i {
        color: var(--primary-color);
        font-size: 28px;
        text-align: right;
      }
    }
  }

  .summary-row {
    margin-top: 12px;
  }
}

.product-img-listview {
  display: none;
}

.listview {
  h1 {
    font-size: 1.4 * $txt-product-title;
    padding-right: 100px;
  }

  h5 {
    font-size: 1.2 * $text-summary;
  }

  .product-description {
    font-size: 1.2 * $text-product-description;
  }

  .product-subtitle li {
    font-size: 1.2 * $text-summary;

    &.li-footnote {
      font-size: 0.8rem !important;
    }
  }

  .stock-limit {
    span {
      display: block;
    }
  }
}

.sideview {
  strong {
    color: #aaa;
  }
}

.bright-colors {
  strong {
    color: #aaa;
  }
}

.payment-checkboxes {
  margin-top: 0.5rem;

  .field {
    text-align: left;
  }
}

.chckt-button__amount {
  display: none;
}

.link {
  color: var(--primary-color);
  cursor: pointer;
}

.checkout-section {
  .summary-section {
    display: flex;
    flex-direction: column;
    margin: 40px;

    .included-product {
      display: flex;
      padding: 6px;
      max-width: 340px;

      .included-product-name {
        flex-basis: 200px;
      }
    }

    .selected-product {
      border-bottom: 2px solid #ccc;
      background-color: #eee;
    }

    .currency-input {
      font-size: 1.2em;
      border: 0px;
      border-bottom: 1px solid var(--primary-color);
      height: 2em;
      width: 10em;
      cursor: pointer;
      text-align: center;
    }
  }

  .price-section {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .listview {
    background-color: red;

    .product {
      display: flex;
      flex-flow: column !important;

      .product-img-listview {
        display: none;
      }

      .text-product-cont {
        max-width: 100% !important;
        width: 100% !important;
        margin-left: 0 !important;
        order: 1;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .listview {
    .ribbon {
      width: 110px;
      height: 110px;

      span {
        width: 275px;
        padding: 14px 0px;
        font-size: 0.9em;
        line-height: 0.7em;
        right: -55px;
        top: 15px;
      }
    }

    .product-container {
      background-color: transparent;

      .product {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        background: none;

        min-height: 200px;

        .left-column {
          display: flex;
          flex-flow: column;
          justify-content: space-around;
          background-color: #fff;
          width: 25%;
          min-height: 250px;

          .product-img {
            display: none;
          }

          .product-img-listview {
            display: inline-block;
            width: 100%;
            height: 100%;
            min-height: 200px;
            background-size: cover;
            background-position: 50% 0%;
            background-repeat: no-repeat;
            background-color: #fff;
          }
        }

        .text-product-cont {
          background-color: #fff;
          width: 75%;

          h1 {
            color: var(--secondary-color);
          }

          .product-description {
            color: var(--secondary-color);

            a {
              &:hover {
                color: var(--secondary-color);
              }
            }
          }
        }
      }
    }

    .action-row {
      color: var(--secondary-color) !important;

      h5 {
        color: var(--secondary-color) !important;
      }

      hr {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .listview {
    .product-img-listview {
      width: 50% !important;
    }

    .text-product-cont {
      max-width: 50% !important;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .listview {
    .product-img-listview {
      width: 50%;
    }

    .text-product-cont {
      background-color: #1e1b26;
      max-width: 50%;
      width: 50%;
    }
  }
}

@media only screen and (min-width: $mobile-portrait) and (max-width: $mobile) {
  .sideview {
    h1 {
      font-size: 1.7 * $txt-product-title;
    }

    h5 {
      font-size: 1.2 * $text-summary;
    }

    .product-description {
      font-size: 1.2 * $text-product-description;
    }

    .product-subtitle li {
      font-size: 1.2 * $text-summary;

      &.li-footnote {
        font-size: 0.8rem !important;
      }
    }
  }
}

.product-container h1,
.product-container h2,
.product-container h3,
.product-container h4,
.product-container h5 {
  color: $gt-blue-light;
  margin: 0px;
}

.product-container h1 {
  font-size: $txt-product-title;
  font-weight: $font-regular;
}

.product-container h5 {
  font-size: $text-summary;
  font-weight: $font-regular;
}

.product-title {
  text-align: left;
}

.product-meta {
  display: flex;
  justify-content: space-between;
}

.product-subtitle {
  margin: 0px;
  padding: 0px;

  li {
    font-size: $text-summary;
    color: darken($gt-blue-light, 40%);
    width: auto;
    display: block;
    margin-right: 6px;
    font-weight: $semi-bold;
  }
}

.product-likes {
  @extend .product-subtitle;
  float: right;

  li {
    color: var(--primary-color);

    &:last-child {
      margin-right: 0px;
    }

    i {
      font-size: 14px;
      margin-right: 4px;
      position: relative;
      float: left;
      line-height: 1;
    }
  }
}

.product-details {
  font-size: $text-summary;
  font-weight: $light;
  color: darken($gt-blue-light, 20%);
  @extend .elements-distance;
}

.product-description {
  font-size: $text-product-description;
  font-weight: $font-regular;
  color: darken($gt-blue-light, 30%);
  @extend .elements-distance;

  a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    &:hover {
      color: $gt-blue-light;
    }
  }
}

.product-tos {
  font-size: $text-summary;
  font-weight: $light;
  color: $gt-blue-light;
  font-style: italic;
  @extend .elements-distance;
}

.thegrid {
  margin: 0 auto;
}

.elements-distance {
  margin: 0px;
}

.mr-grid {
  width: 100%;
}

.mr-grid:before,
.mr-grid:after {
  content: "";
  display: table;
}

.mr-grid:after {
  clear: both;
}

.mr-grid {
  *zoom: 1;
}

.col1,
.col2,
.col3,
.col3rest,
.col4,
.col4rest,
.col5,
.col5rest,
.col6,
.col6rest {
  margin: 0% 0.5% 0.5% 0.5%;
  padding: 1%;
  float: left;
  display: block;
}

.col1 {
  width: 98%;
}

.col2 {
  width: 47%;
}

.col3 {
  width: 30.3333333333%;
}

.col4 {
  width: 22%;
}

.col5 {
  width: 17%;
}

.col6 {
  width: 13.6666666667%;
}

.col3rest {
  width: 63.6666666667%;
}

.col4rest {
  width: 72%;
}

.col5rest {
  width: 77%;
}

.col6rest {
  width: 80.3333333333%;
}

.gui-wrapper {
  min-height: 300px;
}

.placeholder.is-loading {
  position: relative;
  pointer-events: none;
  opacity: 0.5;
  height: 150px;

  &:after {
    @include loader;
    position: absolute;
    top: calc(50% - 2.5em);
    left: calc(50% - 2.5em);
    width: 5em;
    height: 5em;
    border-width: 0.25em;
  }
}

.product-hero {
  position: relative;
  padding: 20px 60px;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
}

.is-hidden {
  display: none;
}

.product-grow {
  display: flex;
  height: 100%;

  .product-background {
    background-size: cover;
    background-position: center center;
    flex-grow: 2;
    filter: blur(10px);
  }

  .product-hero {
    height: 100%;
  }
}

@media only screen and (max-width: $mobile) {
  .product-background {
    display: none;
  }
}

@media only screen and (max-width: $mobile-portrait) {
  .product-hero {
    padding: 0 !important;
  }

  .modal-card {
    margin: 0;
  }
}
</style>
