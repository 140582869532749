<template>
  <div :class="{ 'is-modal': isModal }" class="social-share-wrapper">
    <div @click="toggleShare" v-bind:class="{ 'is-medium': isModal, 'is-small': !isModal, 'is-hidden': visibleButtons }"
      class="social-share-button button">
      <i class="material-icons">&#xE80D;</i>
      <span>{{ $t('share') }}</span>
    </div>
    <div @click="toggleShare"
      v-bind:class="{ 'is-medium': isModal, 'is-small': !isModal, 'is-hidden': !visibleButtons }"
      class="social-share-button button">
      <i class="material-icons">close</i>
      <span>{{ $t('close') }}</span>
    </div>
    <social-sharing class="social-share-container" :class="{ 'is-hidden': !visibleButtons }" style="max-height: 20px"
      :url="fullPath" inline-template>
      <div>
        <network class="network" network="facebook">
          <i class="icon-facebook"></i>
        </network>
        <network class="network" network="twitter">
          <i class="icon-twitter"></i>
        </network>
        <network class="network" network="whatsapp">
          <i class="icon-whatsapp"></i>
        </network>
        <!-- and the others networs tag pasted from the readm.md -->
      </div>
    </social-sharing>
    <button class="button network clipboard-button" :class="{ 'is-hidden': !visibleButtons }" @click="doCopy"
      :title="$t('copyToClipboard')">
      <i class="icon-clipboard"></i>
    </button>
  </div>
</template>
<script>

export default {
  data() {
    return {
      visibleButtons: false,
      message: ''
    }
  },
  props: ['artist', 'post', 'reward', 'isModal', 'fullPath'],
  head() {
    if (this.isModal && this.post) {
      return {
        bodyAttrs: {
          class: 'isProfile unscrollable'
        },
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.socialDescription
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: 'article'
          },
          {
            hid: 'article:author',
            property: 'article:author',
            content: this.artist.nickname
          },
          {
            hid: 'article:published_time',
            property: 'article:published_time',
            content: this.post.publishDateTime
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: this.post.title
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: this.fullPath
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: `${process.env.API_BASE_URL_CLIENT}${this.socialImage}`
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: this.socialDescription
          },
          {
            hid: 'twitter:card',
            property: 'twitter:card',
            content: 'summary_large_image'
          },
          {
            hid: 'twitter:creator',
            property: 'twitter:creator',
            content: this.twitterCreatorAccount
          },
          {
            hid: 'twitter:site',
            property: 'twitter:site',
            content: this.twitterSiteAccount
          }
        ]
      }
    }
    return {
      bodyAttrs: {
        class: 'isProfile'
      }
    }
  },

  methods: {
    toggleShare() {
      this.visibleButtons = !this.visibleButtons
    },
    doCopy(event) {
      if (this.fullPath) {
        navigator.clipboard.writeText(this.fullPath)
        this.sendFlashMessage('success')
      } else {
        this.sendFlashMessage('error')
      }
    },
    sendFlashMessage(state) {
      this.$root.$emit('flash', {
        type: `toasts.share.copied`,
        state
      })
    }
  },

  computed: {
    socialDescription() {
      // Todo: move to API
      const maxLength = 100
      let text

      if (this.post) {
        if (this.post.post) {
          text = this.post.post.replace(/<(.|\n)*?>/g, '')
          if (text.length > maxLength) {
            text = `${text.substring(0, maxLength - 1)}...`
          }
          return text
        }
        text = this.artist.profile.description
        if (text.length > maxLength) {
          text = `${text.substring(0, maxLength - 1)}...`
        }
        return text
      } else if (this.reward) {
        // Todo: handle reward text
      }
    },
    socialImage() {
      if (this.post) {
        const images = this.getImages(this.post.media)
        if (images.length && images[0].availableSizes) {
          const postImageSizes = ['post-xl', 'post-l', 'post-m']
          for (const size of postImageSizes) {
            const img = images[0].availableSizes.find(s => s.name === size)
            if (img && img.path) {
              return img.path
            }
          }
        }
        if (this.artist.profile.imgs.hero.availableSizes) {
          const heroImageSizes = ['hero-xl', 'hero-max', 'hero-m']
          for (const size of heroImageSizes) {
            const img = this.artist.profile.imgs.hero.availableSizes.find(s => s.name === size)
            if (img && img.path) {
              return img.path
            }
          }
        }
        return this.artist.profile.imgs.hero.path
      } else if (this.reward) {
        // Todo: handle image process on reward sharing
      }
    },
    twitterCreatorAccount() {
      const twitter = this.artist.socialAccounts.twitter
      if (twitter && twitter.enabled && twitter.account) {
        return twitter.account.substring(twitter.account.lastIndexOf('/'), twitter.account.length - 1)
      }
      return ''
    },
    twitterSiteAccount() {
      return '@getnext'
    }
  },
  mounted() {
    if (process.client) {
      window.prerenderReady = true
    }
  }
}
</script>
<style lang="scss">
@import "assets/sass/_variables";

.clipboard-button {
  height: 1.6rem;
  font-size: 1.3rem;
  border: 0px;
  padding: 0;

  i {
    padding: 0;
  }
}

.network {
  cursor: pointer;
}

.social-share-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-top: 10px;
  width: 100%;

  &.is-modal {
    margin-top: 9px;

    .social-share-button {
      margin-right: 1em;
    }

    .social-share-container {
      font-size: 2em;
      padding-top: 8px;
    }

    .clipboard-button {
      margin-top: 2px;
      font-size: 1.8rem;
    }
  }

  .social-share-button {
    order: 2;
    padding: 0.5em !important;
    line-height: 1.6rem;

    span {
      vertical-align: middle;
      margin-left: 0.5em;
    }

    .material-icons {
      font-size: 1.4em;
    }
  }

  .social-share-container {
    order: 1;
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding-right: 15px;

    span {
      margin-left: 0.5em;
    }
  }
}
</style>
