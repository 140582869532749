<template>
  <article v-bind:class="{
    sideview: sideview,
    listview: listview,
    highlight: selectedReward === reward.amount
  }">
    <div class="reward-container" v-bind:class="{
      'active-reward-ribbon': reward.activePledge || reward.isPending
    }">
      <div v-if="reward.activePledge" class="ribbon">
        <span>{{ $t('active') }}</span>
      </div>
      <div v-if="reward.isPending" class="ribbon">
        <span>{{ $t('pending') }}</span>
      </div>
      <div v-if="reward.status === 'draft'" class="ribbon draft">
        <span>{{ $t('draft') }}</span>
      </div>
      <div v-if="reward.status === 'scheduled'" class="ribbon scheduled">
        <span>{{ $t('scheduled') }}</span>
      </div>
      <div class="reward" :id="`reward_${reward._id}`" v-bind:class="{
        activePledge: reward.activePledge
      }">
        <div :class="{ 'left-column': !oneColumnLayout }">
          <div class="reward-img-sideview">
            <img class="reward-img" :style="{ backgroundImage: `url(${rewardImage})` }" :src="rewardImage" />
          </div>
          <div class="reward-img-listview" :style="{ backgroundImage: `url(${rewardImage})` }" />

          <portal-target :name="`threeColumns_${reward._id}`"></portal-target>
        </div>

        <div class="text-reward-cont">
          <div class="mr-grid">
            <div class="col1">
              <h1 class="reward-title">{{ reward.name }}</h1>
              <ul class="reward-subtitle">
                <li class="has-text-left">
                  <span class="nowrap">{{ centToEuro(reward.amount, true) }} {{ $t('perMonth') }}</span>
                </li>
                <li class="has-text-left li-footnote"
                  v-if="settings && settings.exchangeRates && settings.exchangeRates.rates.USD">{{
                    $t('rewards.equalsToAboutPerMonth', {
                      amount: centToCurrency(reward.amount,
                        settings.exchangeRates.rates.USD, 'USD')
                    }) }}</li>
              </ul>
            </div>
          </div>
          <div class="mr-grid">
            <div class="col1">
              <div class="reward-description formatted-lists">
                <span :class="{ 'bright-colors': listview && oneColumnLayout }" v-html="reward.description"></span>
              </div>
              <portal-target :name="`oneColumn_${reward._id}`"></portal-target>
            </div>
          </div>
        </div>
      </div>
    </div>
    <portal v-bind:to="columnLayout">
      <div v-if="!reward.isPending && !reward.activePledge">
        <div v-if="reward.status === 'published'" class="action-row">
          <a v-if="artist.isBoarded && !artist.hasPendingPayment" class="col1" :href="checkoutUrl(reward._id)">
            <span :class="{ 'is-white': sideview }"
              class="button pledge-button is-fullwidth is-outlined has-text-weight-bold">
              <span class="pledge-button-text" :class="{
                'is-medium': ['sm', 'md'].includes($mq),
                'is-small': $mq === 'xl',
                'is-smallest': ['lg', 'lgr'].includes($mq)
              }">{{ $t(pledgeControlButtonText) }}</span>
            </span>
          </a>
          <div class="col1 has-text-centered" v-else>
            <h5 v-if="artist.hasPendingPayment" class="title is-5 has-text-weight-light">{{ $t('pendingPayment') }}</h5>
            <h5 v-else class="title is-5 has-text-weight-light">{{ $t('rewardAvailableSoon') }}</h5>
          </div>
        </div>
        <div v-else class="action-row">
          <section class="col1">
            <span class="button is-white is-fullwidth has-text-weight-bold">
              <span v-if="reward.status === 'scheduled'">{{ $t('until') }}: {{ formatDate(reward.publishDateTime)
                }}</span>
              <span v-else>{{ $t('notPublishedYet') }}</span>
            </span>
          </section>
        </div>
      </div>
      <div v-if="reward.isCancelled && reward.accessUntil">
        <div class="action-row">
          <section class="col1 has-text-centered">
            <hr />
            <h5 class>{{ $t('rewards.subscriptionCancelled') }}</h5>
            <h5>
              {{ $t('rewards.accessableContentUntil') }}:
              <br />
              {{ localeDate(reward.accessUntil, $i18n.locale) }}
            </h5>
          </section>
        </div>
      </div>
    </portal>
  </article>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  inject: ['$validator'],
  props: ['reward', 'sideview', 'listview', 'highlight'],
  data: () => ({
    selectedReward: false,
    activeTabName: 'login-tab',
    email: null,
    validEmail: false,
    password: null,
    validPassword: false,
    invalidLoginForm: true,
    socialRegistration: false,
    hasError: false,
    success: false,
    loading: false
  }),

  computed: {
    rewardImage() {
      if (
        !this.artist ||
        !this.reward ||
        !this.reward.image
      ) {
        return ''
      }

      // legacy only in stage
      if (typeof this.reward.image === 'string') {
        return this.reward.image
      }

      let width = 636

      if (this.sideview) {
        width = this.oneColumnLayout ? 984 : 636
      }
      if (this.listview) {
        width = this.oneColumnLayout ? 636 : 480
      }

      return `/services/media/image/${this.artist._id}/${this.reward.image.name}/${width}/0/image.webp`
    },
    oneColumnLayout() { return ['sm', 'md'].includes(this.$mq) },
    columnLayout() {
      return this.oneColumnLayout || this.sideview
        ? `oneColumn_${this.reward._id}`
        : `threeColumns_${this.reward._id}`
    },
    pledgeControlButtonText() {
      return this.selectedReward && this.isAuthenticated ? 'payment.controls.cancelPledgeForReward' : 'payment.controls.pledgeForReward'
    },
    ...mapState(['user', 'artist', 'isAuthenticated', 'settings'])
  },
  watch: {
    email: function () {
      return this.$validator
        .validate('email', this.email)
        .then((response) => {
          this.validEmail = response
          this.invalidLoginForm = !(this.validEmail && this.validPassword)
        })
    },
    password: function () {
      return this.$validator
        .validate('password', this.password)
        .then((response) => {
          this.validPassword = response
          this.invalidLoginForm = !(this.validEmail && this.validPassword)
        })
    }
  },
  methods: {
    async pledgeSuccess() {
      const locale = await this.$i18n.locale
      if (this.$router.currentRoute.fullPath === `/${this.artist.urlAlias}`) {
        this.$router.push({
          name: `id___${locale}`,
          params: { id: this.artist.urlAlias }
        })
      } else {
        this.$router.push({ path: `/${this.artist.urlAlias}` })
      }
    },

    scrollToMe(target) {
      const elem = target
        ? document.getElementById(`reward_${this.reward._id}_${target}`)
        : document.getElementById(`reward_${this.reward._id}`)

      if (elem) {
        this.$scrollTo(elem)
      }
    },
    scrollToMyRewardPayment(rewardId) {
      if (rewardId === this.reward._id) {
        this.scrollToMe('payment')
      }
    },
    rewardSelected(rewardId) {
      if (rewardId !== this.reward._id) {
        this.selectedReward = false
      }
    },
    ...mapActions(['loginPayment', 'reloadPurchasables'])
  },
  mounted() {
    if (
      this.$route.params.rewardId &&
      this.$route.params.rewardId === this.reward._id
    ) {
      if (this.$route.query.payload) {
        this.verify(this.$route.query.payload)
      }
    }

    if (this.highlight === this.reward.amount) {
      this.selectedReward = this.reward._id
    }

    this.$root.$on('rewardSelected', this.rewardSelected)
    this.$root.$on('scrollToRewardPayment', this.scrollToMyRewardPayment)
  },
  created() {
    this.$validator.attach({
      name: 'email',
      rules: {
        required: true,
        email: true
      }
    })
    this.$validator.attach({
      name: 'password',
      rules: {
        required: true
      }
    })
  },
  beforeDestroy() {
    this.$validator.detach('email')
    this.$validator.detach('password')
    this.$root.$off('rewardSelected', this.rewardSelected)
    this.$root.$off('scrollToRewardPayment', this.scrollToMyRewardPayment)
  }
}
</script>

<style lang="scss">
@import "assets/sass/_variables";

$light: 300;
$semi-bold: 600;
$bold: 700;
$font-regular: 400;

$txt-reward-title: 1.4rem;
$text-summary: 0.8rem;
$text-action-btn: 1rem;
$text-reward-description: 0.8rem;

.pledge-button-text {
  &.is-medium {
    font-size: 1rem;
  }

  &.is-small {
    font-size: 0.9rem;
  }

  &.is-smallest {
    font-size: 0.75rem;
  }
}

.highlight {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.sideview,
.listview {
  margin-bottom: 20px;

  strong {
    font-weight: bold;
  }
}

.active-reward-ribbon {
  .ribbon span {
    background-color: var(--primary-color) !important;
  }
}

.ribbon {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: -1px;
  z-index: 2;

  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 1em;
    line-height: 1em;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    right: -35px;
    top: 20px;
    transform: rotate(-45deg);
  }

  &.draft {
    span {
      background-color: #08ae2d;
    }
  }

  &.scheduled {
    span {
      background-color: #fed34b;
    }
  }
}

.reward-container {
  position: relative;
  background-color: #1e1b26;
  margin: 0 auto;

  .reward {
    background: linear-gradient(to bottom,
        rgba(255, 255, 255, 0) 38%,
        rgba(255, 255, 255, 0.06) 39%,
        rgba(30, 27, 38, 0.88) 53%,
        rgba(30, 27, 38, 1) 55%);

    background-size: cover;

    display: block;
    border-radius: 4px;

    .price-row {
      position: relative;
      z-index: 2;
      font-size: 20px;
      font-weight: bold;
      color: var(--primary-color);
      text-align: center;
    }

    .reward-img {
      width: 100%;
      position: relative;
      margin-top: -1px;
      background-size: cover;
      // border-top-left-radius: 4px;
      // border-top-right-radius: 4px;
      -webkit-mask-image: -webkit-gradient(linear,
          left top,
          left bottom,
          color-stop(0, rgba(0, 0, 0, 1)),
          color-stop(0.35, rgba(0, 0, 0, 1)),
          color-stop(0.5, rgba(0, 0, 0, 1)),
          color-stop(0.65, rgba(0, 0, 0, 1)),
          color-stop(0.85, rgba(0, 0, 0, 0.6)),
          color-stop(1, rgba(0, 0, 0, 0)));
    }

    .text-reward-cont {
      padding: 0px 12px;
    }
  }

  .action-row {
    margin-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .pledge-button {
      padding: 1rem !important;
    }

    .share-btn {
      text-align: right;
      padding-top: 5px;

      i {
        color: var(--primary-color);
        font-size: 28px;
        text-align: right;
      }
    }
  }

  .summary-row {
    margin-top: 12px;
  }
}

.reward-img-listview {
  display: none;
}

.listview {
  h1 {
    font-size: 1.4 * $txt-reward-title;
  }

  h5 {
    font-size: 1.2 * $text-summary;
  }

  .reward-description {
    font-size: 1.2 * $text-reward-description;
  }

  .reward-subtitle li {
    font-size: 1.2 * $text-summary;

    &.li-footnote {
      font-size: 0.8rem !important;
    }
  }
}

.sideview {
  strong {
    color: #aaa;
  }
}

.bright-colors {
  strong {
    color: #aaa;
  }
}

.payment-checkboxes {
  margin-top: 0.5rem;

  .field {
    text-align: left;
  }
}

.chckt-button__amount {
  display: none;
}

.link {
  color: var(--primary-color);
  cursor: pointer;
}

.checkout-section {
  .summary-section {
    display: flex;
    flex-direction: column;
    margin: 40px;

    .included-reward {
      display: flex;
      padding: 6px;
      max-width: 340px;

      .included-reward-name {
        flex-basis: 200px;
      }
    }

    .selected-reward {
      border-bottom: 2px solid #ccc;
      background-color: #eee;
    }
  }

  .price-section {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .listview {
    background-color: red;

    .reward {
      display: flex;
      flex-flow: column !important;

      .reward-img-listview {
        display: none;
      }

      .text-reward-cont {
        max-width: 100% !important;
        width: 100% !important;
        margin-left: 0 !important;
        order: 1;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .listview {
    .ribbon {
      width: 110px;
      height: 110px;

      span {
        width: 275px;
        padding: 14px 0px;
        font-size: 0.9em;
        line-height: 0.7em;
        right: -55px;
        top: 15px;
      }
    }

    .reward-container {
      background-color: transparent;

      .reward {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        background: none;

        min-height: 200px;

        .left-column {
          display: flex;
          flex-flow: column;
          justify-content: space-around;
          background-color: #fff;
          width: 25%;
          min-height: 250px;

          .reward-img {
            display: none;
          }

          .reward-img-listview {
            display: inline-block;
            width: 100%;
            height: 100%;
            min-height: 200px;
            background-size: cover;
            background-position: 50% 0%;
            background-repeat: no-repeat;
            background-color: #fff;
          }
        }

        .text-reward-cont {
          background-color: #fff;
          width: 75%;

          h1 {
            color: var(--secondary-color);
          }

          .reward-description {
            color: var(--secondary-color);

            a {
              &:hover {
                color: var(--secondary-color);
              }
            }
          }
        }
      }
    }

    .action-row {
      color: var(--secondary-color) !important;

      h5 {
        color: var(--secondary-color) !important;
      }

      hr {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  .listview {
    .reward-img-listview {
      width: 50% !important;
    }

    .text-reward-cont {
      max-width: 50% !important;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .listview {
    .reward-img-listview {
      width: 50%;
    }

    .text-reward-cont {
      background-color: #1e1b26;
      max-width: 50%;
      width: 50%;
    }
  }
}

@media only screen and (min-width: $mobile-portrait) and (max-width: $mobile) {
  .sideview {
    h1 {
      font-size: 1.7 * $txt-reward-title;
    }

    h5 {
      font-size: 1.2 * $text-summary;
    }

    .reward-description {
      font-size: 1.2 * $text-reward-description;
    }

    .reward-subtitle li {
      font-size: 1.2 * $text-summary;

      &.li-footnote {
        font-size: 0.8rem !important;
      }
    }
  }
}

.reward-container h1,
.reward-container h2,
.reward-container h3,
.reward-container h4,
.reward-container h5 {
  color: $gt-blue-light;
  margin: 0px;
}

.reward-container h1 {
  font-size: $txt-reward-title;
  font-weight: $font-regular;
}

.reward-container h5 {
  font-size: $text-summary;
  font-weight: $font-regular;
}

.reward-title {
  text-align: left;
}

.reward-subtitle {
  margin: 0px;
  padding: 0px;

  li {
    font-size: $text-summary;
    color: darken($gt-blue-light, 40%);
    width: auto;
    display: block;
    margin-right: 6px;
    font-weight: $semi-bold;
  }
}

.reward-likes {
  @extend .reward-subtitle;
  float: right;

  li {
    color: var(--primary-color);

    &:last-child {
      margin-right: 0px;
    }

    i {
      font-size: 14px;
      margin-right: 4px;
      position: relative;
      float: left;
      line-height: 1;
    }
  }
}

.reward-details {
  font-size: $text-summary;
  font-weight: $light;
  color: darken($gt-blue-light, 20%);
  @extend .elements-distance;
}

.reward-description {
  font-size: $text-reward-description;
  font-weight: $font-regular;
  color: darken($gt-blue-light, 30%);
  @extend .elements-distance;

  a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    &:hover {
      color: $gt-blue-light;
    }
  }
}

.reward-tos {
  font-size: $text-summary;
  font-weight: $light;
  color: $gt-blue-light;
  font-style: italic;
  @extend .elements-distance;
}

.thegrid {
  margin: 0 auto;
}

.elements-distance {
  margin: 0px;
}

.mr-grid {
  width: 100%;
}

.mr-grid:before,
.mr-grid:after {
  content: "";
  display: table;
}

.mr-grid:after {
  clear: both;
}

.mr-grid {
  *zoom: 1;
}

.col1,
.col2,
.col3,
.col3rest,
.col4,
.col4rest,
.col5,
.col5rest,
.col6,
.col6rest {
  margin: 0% 0.5% 0.5% 0.5%;
  padding: 1%;
  float: left;
  display: block;
}

.col1 {
  width: 98%;
}

.col2 {
  width: 47%;
}

.col3 {
  width: 30.3333333333%;
}

.col4 {
  width: 22%;
}

.col5 {
  width: 17%;
}

.col6 {
  width: 13.6666666667%;
}

.col3rest {
  width: 63.6666666667%;
}

.col4rest {
  width: 72%;
}

.col5rest {
  width: 77%;
}

.col6rest {
  width: 80.3333333333%;
}

.gui-wrapper {
  min-height: 300px;
}

.placeholder.is-loading {
  position: relative;
  pointer-events: none;
  opacity: 0.5;
  height: 150px;

  &:after {
    @include loader;
    position: absolute;
    top: calc(50% - 2.5em);
    left: calc(50% - 2.5em);
    width: 5em;
    height: 5em;
    border-width: 0.25em;
  }
}

.reward-hero {
  position: relative;
  padding: 20px 60px;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
}

.is-hidden {
  display: none;
}

.reward-grow {
  display: flex;
  height: 100%;

  .reward-background {
    background-size: cover;
    background-position: center center;
    flex-grow: 2;
    filter: blur(10px);
  }

  .reward-hero {
    height: 100%;
  }
}

@media only screen and (max-width: $mobile) {
  .reward-background {
    display: none;
  }
}

@media only screen and (max-width: $mobile-portrait) {
  .reward-hero {
    padding: 0 !important;
  }
}
</style>
