<template>
  <div>
    <div
      v-if="sortedRewards.length || sortedProducts.length"
      class="purchasables-controls">
      <button
        v-show="sortedRewards.length"
        @click="toggleRewards"
        :class="{
          'is-primary': showRewards,
          'is-one-column is-fullwidth is-small': $mq === 'sm',
          'is-one-column is-fullwidth is-medium': $mq === 'md',
          'is-three-colum-widest is-small': $mq === 'xxl',
          'is-three-colum-wide is-small': $mq === 'xl',
          'is-three-colum-medium is-small': $mq === 'lgr',
          'is-three-colum-narrow is-small': $mq === 'lg',
          'no-margin' : !sortedProducts.length
        }"
        class="button">{{ $t('rewards.name') }} ({{ artist.rewards.length }})</button>

        <button
        v-show="sortedProducts.length"
        @click="toggleProducts"
        :class="{
          'is-primary': showProducts,
          'is-one-column is-fullwidth is-small': $mq === 'sm',
          'is-one-column is-fullwidth is-medium': $mq === 'md',
          'is-three-colum-widest is-small': $mq === 'xxl',
          'is-three-colum-wide is-small': $mq === 'xl',
          'is-three-colum-medium is-small': $mq === 'lgr',
          'is-three-colum-narrow is-small': $mq === 'lg',
          'no-margin' : !sortedRewards.length
        }"
        class="button is-small">{{ $t('singleRewards.name') }} ({{ artist.products.length }})</button>
    </div>
    <div
      v-show="showRewards && sortedRewards.length">
      <div v-show="isMobileView && artist.rewards.length > 0"
        class="container has-text-centered mobile-purchasable-title">
        <h3 class="title is-3 has-text-weight-semibold pb-5">{{ $t('rewards.name') }}</h3>
      </div>
      <profile-reward
        :reward="reward"
        :key="reward._id"
        :sideview="sideview"
        :index="index"
        v-for="(reward, index) in sortedRewards" />
    </div>
    <div
      v-show="showProducts && sortedProducts.length">
      <div v-show="isMobileView && artist.products.length > 0"
        class="container has-text-centered mobile-purchasable-title">
        <h3 class="title is-3 has-text-weight-semibold pb-5">{{ $t('singleRewards.name') }}</h3>
      </div>
      <profile-product
        :product="product"
        :key="product._id"
        :sideview="sideview"
        :index="index"
        v-for="(product, index) in sortedProducts" />
    </div>
  </div>
</template>

<style lang="scss">
.mobile-purchasable-title {
  background-color: black;
  h3 {
    color: white;
    padding-top: 5px;
    line-height: 1em;
  }
}
</style>

<script>
import { mapState } from 'vuex'
import profileReward from '~/components/profile-reward'
import profileProduct from '~/components/profile-product'
export default {
  props: ['sideview'],
  components: {
    profileReward,
    profileProduct
  },

  data() {
    return {
      showProducts: false,
      showRewards: true
    }
  },

  computed: {
    ...mapState(['artist', 'selectedPurchasables']),
    sortedRewards() {
      if (this.artist && this.artist.rewards) {
        return this.artist.rewards.sort((a, b) => {
          if (a.amount > b.amount) {
            return 1
          }
          if (a.amount < b.amount) {
            return -1
          }
          return 0
        })
      }
      return []
    },
    sortedProducts() {
      if (this.artist && this.artist.products) {
        return this.artist.products.sort((a, b) => {
          const amountA = a.minAmount > 0 ? a.minAmount : a.amount
          const amountB = b.minAmount > 0 ? b.minAmount : b.amount
          if (amountA > amountB) {
            return 1
          }
          if (amountA < amountB) {
            return -1
          }
          return 0
        })
      }
      return []
    }
  },

  methods: {
    toggleRewards() {
      this.showRewards = true
      this.showProducts = false
      this.$store.commit('toggleSelectedPurchasables', 'rewards')
    },
    toggleProducts() {
      this.showRewards = false
      this.showProducts = true
      this.$store.commit('toggleSelectedPurchasables', 'products')
    }
  },
  mounted() {
    if (
      this.selectedPurchasables === 'products' ||
      (!this.sortedRewards.length && this.sortedProducts.length)
    ) {
      this.toggleProducts()
    }
  }
}
</script>
<style lang="scss">
.purchasables-controls {
  padding: 0;
  margin: 0 0 15px 0;
  display: flex;
  justify-content: space-between;

  button {
    margin: 0px;
    flex-grow:1;
    cursor: pointer;

    &.no-margin {
      margin-left: 0px !important;
      width: 100%;
    }

    &.is-one-colum {
      padding-left: 60px;
      padding-right: 60px;
    }
    &.is-three-colum-narrow {
      padding-left: 10px;
      padding-right: 10px;
    }
    &.is-three-colum-medium {
      padding-left: 20px;
      padding-right: 20px;
    }
    &.is-three-colum-wide {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
</style>


