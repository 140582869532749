<template>
  <div>
    <div v-if="hlsMediaAvailable || legacyMediaAvailable" @contextmenu="handleContextMenu">
      <div v-show="sourcesAvailable" class="audio-parent">
        <audio :ref="audioId" :id="audioId" class="audio-player-box video-js video-getnext"
          :class="{ 'no-poster': noPoster }">
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
          </p>
        </audio>
      </div>
      <div v-if="!sourcesAvailable" class="audio-placeholder">
        {{ $t('audioNotPlayable') }}
      </div>
    </div>
    <div v-else class="audio-placeholder">
      {{ $t('audioConverting') }}
    </div>
  </div>
</template>
<script>
import videojs from 'video.js'
require('@/lib/player/vjsDownload')
require('videojs-seek-buttons')

export default {
  props: ['audio', 'downloadName'],

  data() {
    return {
      hlsSource: false,
      playsinline: true,
      player: null,
      seekButtons: {
        forward: 15,
        back: 15
      },
      options: {}
    }
  },
  computed: {
    audioId() {
      return `audio_${this.audio.name.replaceAll('-', '')}`
    },
    hlsMediaAvailable() { return (this.audio || {}).master },
    legacyMediaAvailable() { return (this.audio || {}).path },
    sourcesAvailable() { return (this.options.sources || []).length },
    hasCoverImage() { return (this.audio || {}).meta && this.audio.meta.coverImage && !this.audio.meta.hiddenCoverImage },
    noPoster() { return !this.audio.meta.coverImage || this.audio.meta.hiddenCoverImage }
  },
  methods: {
    setSource() {
      if (this.hlsMediaAvailable) {
        this.hlsSource = this.audio.mimeType === 'audio/mpeg'
        if (this.hlsSource) {
          return [{
            type: 'application/x-mpegurl',
            src: this.audio.master
          }]
        }
      }
      return [{
        type: this.audio.mimeType,
        src: this.audio.path
      }]
    },
    init() {
      if (!this.$refs[this.audioId]) {
        console.log('Audio player not initialized for id', this.audioId)
        return
      }

      this.options = {
        fluid: true,
        playsinline: true,
        autoplay: false,
        controls: true,
        preload: 'auto',
        audioOnlyMode: true,
        playbackRates: [0.5, 1, 1.5, 2],
        inactivityTimeout: 0,
        controlBar: {
          volumePanel: { inline: true }
        },
        poster: this.audio.poster || '',
        sources: this.setSource(),
        language: this.$i18n.locale,
        userActions: {
          hotkeys: event => {
            switch (event.code) {
              case 'Space':
                if (this.player.paused()) {
                  this.player.play()
                } else {
                  this.player.pause()
                }
                break
              case 'ArrowRight':
                this.player.currentTime(this.player.currentTime() + this.seekButtons.forward)
                break
              case 'ArrowLeft':
                this.player.currentTime(this.player.currentTime() - this.seekButtons.back)
                break
              case 'ArrowUp':
                this.player.volume(this.player.volume() + 0.05)
                break
              case 'ArrowDown':
                this.player.volume(this.player.volume() - 0.05)
                break
              case 'KeyM':
                if (this.player.muted()) {
                  this.player.muted(false)
                } else {
                  this.player.muted(true)
                }
                break
              // case 'KeyF':
              //   if (this.player.isFullscreen()) {
              //     this.player.exitFullscreen()
              //   } else {
              //     this.player.requestFullscreen()
              //   }
              //   break
              default:
                break
            }
          }
        },
        html5: {
          vhs: {
            overrideNative: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        }
      }

      if (this.hasCoverImage) {
        const img = this.audio.meta.coverImage
        this.options.poster = `${this.audio.baseDir}/${img.fileName}.${img.fileExtension}`
        this.options.audioPosterMode = true
        this.options.bigPlayButton = true
      }

      const downloadButton = {}
      if (this.audio.meta.enableDownloadButton) {
        downloadButton.plugins = {
          vjsdownload: {
            beforeElement: 'playbackRateMenuButton',
            textControl: 'Download Audio',
            name: 'downloadButton',
            downloadName: this.addFileExtension(this.downloadName, this.audio.mimeType)
          }
        }
      }

      this.player = videojs(this.$refs[this.audioId], {
        ...this.options,
        ...downloadButton
      })

      this.player.seekButtons({
        forward: 10,
        back: 10
      })
      this.player.on('ready', () => { })
      this.player.on('ended', () => { })
      this.player.on('play', () => { })
      this.player.on('pause', () => { })
      this.player.on('error', () => { })
      this.player.on('waiting', () => { })
      this.player.on('emptied', () => { })
    },
    addFileExtension(name, mimeType) {
      switch (mimeType) {
        case 'audio/mpeg':
          return `${name}.mp3`
        case 'audio/wav':
          return `${name}.wav`
        case 'audio/ogg':
          return `${name}.oga`
        default:
          return `${name}.mp3`
      }
    },
    handleContextMenu(event) {
      event.preventDefault()
    }
  },

  mounted() {
    setTimeout(() => {
      this.init()
    }, 200)
  },
  beforeDestroy() {
    if (this.player) this.player.dispose()
  }
}
</script>

<style lang="scss">
@import 'assets/sass/video-player';

.audio-placeholder {
  width: 100%;
  font-style: italic;
  background-color: #b0b0b0;
}

.audio-player-box {
  .vjs-playback-rate {
    width: 5em !important;

    .vjs-menu {
      left: -3em;
    }
  }

  .vjs-picture-in-picture-control {
    display: none;
  }

  &.no-poster {
    padding-top: 0px;
    height: 55px;
    background-color: transparent;

    .vjs-big-play-button {
      display: none !important;
    }

    &:not(.vjs-has-started) {
      .vjs-control-bar {
        display: flex;
      }
    }

    .vjs-control-bar {
      background-color: #000 !important;

      .vjs-playback-rate {
        .vjs-menu {
          display: none;
        }
      }
    }

    .vjs-fullscreen-control {
      display: none;
    }
  }

  &.hidden-video {

    .vjs-captions-button,
    .vjs-chapters-button,
    .vjs-descriptions-button,
    .vjs-tech {
      display: none;
    }
  }

  &.hidden-controls {

    .vjs-current-time,
    .vjs-time-divider,
    .vjs-time-control,
    .vjs-playback-rate {
      display: none
    }
  }

}
</style>
