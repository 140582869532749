<template>
  <div class="twitch">
    <div class="twitch-video" :ref="refId" />
    <div class="twitch-chat">
      <iframe frameborder="0"
        scrolling="no"
        :id="this.channel"
        :src="`https://www.twitch.tv/embed/${this.channel}/chat?${parentURLString}`"
      >
      </iframe>
    </div>
  </div>
</template>
<script>

export default {
  name: 'twitch-video',
  props: ['channel', 'refId'],
  data() {
    return {
      player: null,
      options: {
        theme: 'light',
        channel: this.channel,
        parent: []
      }
    }
  },
  computed: {
    parentURLString() {
      const parent = this.parent()
      let s = ''
      for (const p of parent) {
        s = `${s}&parent=${p}`
      }
      return s.substring(1, s.length)
    }
  },
  methods: {
    play() {
      if (!this.player) return
      this.player.play()
    },
    parent() {
      const p = []
      for (const locale of this.$i18n.locales) {
        p.push(locale.domain)
      }
      return p
    }
  },
  mounted() {
    for (const locale of this.$i18n.locales) {
      this.options.parent.push(locale.domain)
    }
    this.options.parent = this.parent()
    this.player = new window.Twitch.Player(this.$refs[this.refId], this.options)
    this.player.addEventListener(window.Twitch.Embed.VIDEO_READY, this.play)
  },
  beforeDestroy() {
    if (this.player) {
      this.player.removeEventListener(window.Twitch.Embed.VIDEO_READY, this.play)
    }
  }
}
</script>
<style lang="scss">
.twitch {
  position: relative;
  width: 100%;
  height: 100%;
  .twitch-video {
    padding-top: 56.25%;
    position: relative;
    height: 0;
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      border: none;
      top: 0;
    }
  }
  .twitch-chat {
    height: 400px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
