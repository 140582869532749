<template>
  <div id="supportcta-wrapper">
    <nuxt-link :to="localePath({ name: 'id-rewards', params: { id: artist.urlAlias } })"
      id="supportcta"
      class="button is-fullwidth mb-20 is-large is-primary has-text-weight-semibold">
      {{$t('closerToMe')}}
    </nuxt-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['artist'])
  }
}
</script>
