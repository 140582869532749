<template>
  <div class="preview-img-list">
    <figure v-for="(item, index) in items" v-bind:key="index">
      <img class="preview-img-item" :src="item.preview" @click="$photoswipe.open(index, items)" />
      <figcaption>{{ item.title }}</figcaption>
    </figure>
  </div>
</template>

<script>
export default {
  props: ['images', 'mediaCategory'],

  computed: {
    items: function () {
      return this.images.map((image) => {
        return this.getImageObject(image)
      })
    }
  },
  methods: {
    getImageObject(image) {
      const title = image.meta ? image.meta.displayName : ''

      if (!image.availableSizes || !image.availableSizes.length) {
        return {
          src: image.path,
          w: 1200,
          h: 800,
          title: title,
          preview: image.path
        }
      }

      let largeVariant = `${this.mediaCategory ? this.mediaCategory : 'post'}-xl`
      let previewVariant = `${this.mediaCategory ? this.mediaCategory : 'post'}-m`

      const imageObject = image.availableSizes.find(size => size.name === largeVariant)
      const previewObject = image.availableSizes.find(size => size.name === previewVariant)

      return {
        src: imageObject.path,
        w: imageObject.width,
        h: imageObject.height,
        title: title,
        preview: previewObject.path
      }
    }
  }
}
</script>

<style lang="scss">
.preview-img-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>
